body {
  font-family: -apple-system, "Inter", BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.navbar-item img {
  max-height: 35px !important;
}

.tailosive-hero {
  padding: 10%;
  padding-left: 10%;
  padding-right: 10%;
}

.tailosive-title {
  padding-top: 10%;
  font-size: 35px;
  font-weight: 900;
  margin-bottom: 10;
}

.tailosive-subtitle {
  font-size: 15px;
}

.tailosive-ul li {
  width: 100%;
  text-align: center;
  background-color: #171717;
  color: #ffffff !important;
}

.tailosive-ul li:first-child {
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.tailosive-ul li:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.tailosive-section {
  padding: 2.5%;
  padding-left: 10%;
  padding-right: 10%;
}

li {
  list-style-position: inside;
}

@media (max-width: 1023px) {
  .tailosive-shirts {
    display: grid !important;
    grid-auto-columns: 40%;
    grid-auto-flow: column;
    overflow: scroll;
  }
}
@media only screen and (min-width: 1024px) {
  .bingo-flex {
    display: flex;
  }
  .navbar-item img {
    max-height: 50px !important;
  }

  .tailosive-section {
    padding: 2.5%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .tailosive-title {
    padding-top: 5%;
    font-size: 50px;
  }

  .tailosive-subtitle {
    font-size: 25px;
  }

  .tailosive-hero {
    padding: 2.5%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .tailosive-ul {
    width: 100%;
    flex-direction: row;
    display: flex;
  }

  .tailosive-ul li {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    color: #ffffff !important;
  }

  .tailosive-ui h4 {
    color: #ffffff;
  }

  .tailosive-ul li:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .tailosive-ul li:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0;
  }
}

.banner-card {
  background-color: #eeeeee;
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 2%;
}

.colored-section {
  background-color: #eeeeee;
  margin-top: 30px;
}

@media (prefers-color-scheme: dark) {
  .tailosive-ul li {
    background-color: #171717;
  }
  .colored-section {
    background-color: #343a40;
    margin-top: 30px;
  }
  .banner-card {
    background-color: #343a40;
  }
  .title {
    color: #ffffff;
  }

  html, body {
    background-color: #171717;
    color: #ffffff;
  }

  .navbar-item, .navbar-link {
    color: #ffffff;
  }

  .navbar-link.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, a.navbar-item.is-active, a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover {
    color: #9e9e9e;
    background-color: transparent;
    transition: ease-in 0.3s;
  }

  strong {
    color: #ffffff;
    font-weight: 900;
  }
  .outer {
    background-color: #343a40;
  }
}
.outer {
  position: relative;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
}
.outer:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.outer > .inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.activeBingo {
  background-color: #789bfc !important;
}

.inner {
  text-align: center;
  padding-top: 35%;
  font-weight: 800;
  font-size: 2rem;
}